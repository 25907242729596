import React from 'react';
import PropTypes from 'prop-types';
import { Head } from 'react-static';
import styled from 'styled-components';
import { ModalConsumer } from '../components/ModalProvider';
import MenuContent from '../components/Menu/MenuContent';

const Nothing = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
`;

class Wrapper extends React.Component {
  componentDidMount() {
    const { showMenu } = this.props;
    showMenu();
  }

  render() {
    const { showMenu } = this.props;
    return (
      <Nothing showMenu={showMenu}>
        <Head title="Fabian Schroder" />
        <noscript>
          <MenuContent />
        </noscript>
      </Nothing>
    );
  }
}

Wrapper.propTypes = {
  showMenu: PropTypes.func.isRequired,
};

const Home = () => (
  <ModalConsumer>
    {({ showMenu }) => (
      <Wrapper showMenu={showMenu} />
    )}
  </ModalConsumer>
);

export default Home;
